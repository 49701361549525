.road {
    background-image: url('../../../public/img/bg7.png');
    background-color: #E8EBE4;
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: 100% 80%;
}

.content-road {
    display: flex;
}

.content-road-left {
    display: flex;
    flex-direction: column;
    margin-left: 180px;
    margin-right: 100px;
    h1 {
        font-size: 56px;
        margin: 75px 0 35px 0px;
        font-family: Prata;
    }
    p {
        width: 452px;
        font-family: Bitter;
        font-size: 24px;
        margin: 0 194px 0 45px;
    }
    .road-map {
        width: 700px;
        height: 500px;
    }
}

.btn-excurs {
    display: flex;
    align-items: center;
    margin: 41px 0 130px 0;
    a {
        background-color: #092B1A;
        font-weight: 400;
        font-size: 16px;
        padding: 25px 80px;
        border-radius: 49px;
        color: #ffffff;
        transition: 0.3s;
    }
    a:hover {
        background-color: #05170E;
        color: #FCFBFB;
    }
}

.content-road-right {
    margin-top: 110px;
}

.footer-contacts {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: 211px;
    background-color: #092B1A;
    .info-left {
        padding: 33px 0px 33px 81px;
        font-family: Bitter;
        color: #E8EBE4;
        font-size: 24px;
    }
    .info-right {
        padding: 6px 170px 0 0;
        font-family: Bitter;
        font-size: 16px;
        color: #E8EBE4;
    }

}



@media (max-width: 1650px) {
    .road-map {
        width: 550px;
        height: 500px;
    }
}

@media (max-width: 1600px) {
    .road-map {
        width: 500px;
        height: 500px;
    }
}
@media (max-width: 1550px) {
    .road-map {
        width: 400px;
        height: 400px;
    }
}
@media (max-width: 1440px) {
    .content-road-left {
        margin-right: 20px;
    }
    .road-map {
        width: 400px;
        height: 400px;
    }
}


@media screen and (max-width: 1280px) {
    .content-road-left {
        display: flex;
        flex-direction: column;
        margin: 0 20px 0 50px;
    }
    .road-map {
        width: 400px;
        height: 400px;
    }
    .footer-contacts {
        height: 235px;
    }
}


@media screen and (max-device-width: 1024px)  {
    .content-road-left {
        display: flex;
        flex-direction: column;
        margin: 0 20px 0 50px;
        h1 {
            font-size: 40px;
            width: 500px;
        }
        p {
            width: 452px;
            font-family: Bitter;
            font-size: 24px;
            margin: 0 20px 0 45px;
        }
    }
    .road-map {
        width: 350px;
        height: 350px;
    }
    .footer-contacts {
        height: 250px;
    }
}

@media screen and (max-device-width: 820px)  {
    .content-road {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
    }
    .road-map {
        width: 600px;
        height: 400px;
    }
    .content-road-right {
        display: flex;
        justify-content: center;
        margin: 10px 0 50px 0;
    }
    .btn-excurs {
        margin: 41px 0 20px 0;
        display: flex;
        justify-content:  center;
    }
    .footer-contacts {
        display: flex;
        align-items: center;
        height: 300px;
        .info-left {
            padding: 0px 0px 0px 20px;
        }
        .info-right {
            padding: 0px 20px 0 0;

        }
    }
}


@media screen and (max-device-width: 768px)  {
    .content-road {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
    }
    .road-map {
        width: 600px;
        height: 400px;
    }
    .content-road-right {
        display: flex;
        justify-content: center;
        margin: 10px 0 50px 0;
    }
    .btn-excurs {
        margin: 41px 0 20px 0;
        display: flex;
        justify-content:  center;
    }
    .footer-contacts {
        display: flex;
        align-items: center;
        height: 300px;
        .info-left {
            padding: 0px 0px 0px 20px;
        }
        .info-right {
            padding: 0px 20px 0 0;

        }
    }
}

 @media screen and (max-device-width: 480px) {
    .content-road-left {
        margin: 0 0 50px 0;
        width: 100%;
        align-items: center;
        h1 {
            margin: 10px 0 20px 0;
            width: 90vw;
            font-size: 34px;
        }
        p {
            margin: 0;
            width: 90vw;
            
        }
     }
    .road-map {
        width: 20em;
        height: 15em;
    }
    .footer-contacts {
        height: 220px;
        .info-left {
            padding: 0px 0px 0px 10px;
            font-size: 16px;
        }
        .info-right {
            padding: 0px 10px 0 0;
            font-size: 14px;
        }
    }
}

@media screen and (max-device-width: 420px) {
    .footer-contacts {
        height: 250px;
        .info-left {
            padding: 0px 0px 0px 10px;
            font-size: 16px;
        }
        .info-right {
            padding: 0px 10px 0 0;
            font-size: 14px;
        }
    }
 }




@media screen and (max-device-width: 320px) {
    .content-road-left {
        margin-bottom: 0px;
        text-align: center;
        h1 {
            font-size: 24px;
        }
        p {
            font-size: 16px;
        }
    }
    .road-map {
        display: none;
    }

    .footer-contacts {
        height: 220px;
        .info-left {
            padding: 0px 0px 0px 10px;
            font-size: 14px;
        }
        .info-right {
            padding: 0px 10px 0 0;
            font-size: 10px;
        }
    }
    .btn-excurs {
        a {
            padding: 12px 30px;
            font-family: Bitter;
        }
    }

}