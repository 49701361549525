@import './styles/reset.css';

body {
    margin: 0;
    font-family: Bitter, Prata, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

  }

  body.active {
    overflow: hidden;
  }

  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  }
  
#root {
    min-height: 100vh;
  }
  .App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }


.container {
  margin-left:200px;
}
  
