.home {
    min-height: 90vh;
    background-image: url("./img/bi1.png");
    background-color: #E8EBE4;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100%;
}



.content-home {
    display: flex;
    margin-top: 200px;
    justify-content: center;
}

.description {
    display: flex;
    width: 492px;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin-right: 106px;
    

    img {
        position: absolute;
        top: 50px;
        left: -13px;
        width: 100px;
        height: 85px;
    }
    h3 {
        margin-left: 80px;
        font-size: 24px;
        font-weight: 400;
        font-family: Bitter;
        line-height: 28px;
        color: black;
    }
    h1 {
        font-size: 52px;
        text-align: left;
        font-family: Prata;
    }
    p {
        font-family: Bitter;
        font-size: 20px;
        text-align: left;
        font-weight: 400;
    }
}
.photo {
    img {
        width: 704px;
        height: 432px;
        border-radius: 65px;
    }
}

@media (max-width: 1650px) {
    .content-home {
        display: flex;
        margin-top: 100px;
        justify-content: center;
    }
    
}



 @media screen and (max-width: 1280px) {
    .photo {
        img {
            width: 506px;
            height: 311px;
            border-radius: 65px;
        }
    }
    .description {
        img {
            position: absolute;
            top: -5px;
            left: -15px;
        }
 }
 }

 @media screen and (max-device-width: 1024px) { 
    .photo {
        img {
            width: 343px;
            height: 211px;
            border-radius: 65px;
        }
    }
    .description {
        margin-right: 15px;
        img {
            position: absolute;
            top: -45px;
            left: -10px;
        }
 }
}

@media screen and (max-device-width: 820px)  { 
    .description {
        width: 310px;
        img {
            position: absolute;
            top: -38px;
            left: -10px;
        }
        h3 {
            margin-left: 80px;
            font-size: 16px;
            font-weight: 700;
            font-family: Marmelad;
            line-height: 28px;
            color: black;
        }
        h1 {
            font-size: 24px;
            text-align: left;
            font-family: Montserrat Alternates;
        }
        p {
            font-family: Marmelad;
            font-size: 16px;
            text-align: left;
            font-weight: 700;
        }
    }
    .photo {
        img {
            width: 336px;
            height: 207px;
            border-radius: 65px;
        }
    }
    .description {
        img {
            position: absolute;
            top: -22px;
            left: 0;
            width: 70px;
            height: 60px;
        }
    }
}



@media screen and (max-device-width: 768px)  { 
    .description {
        width: 310px;
        img {
            position: absolute;
            top: -22px;
            left: 0;
            width: 70px;
            height: 60px;
        }
        h3 {
            margin-left: 80px;
            font-size: 16px;
            font-weight: 700;
            font-family: Marmelad;
            line-height: 28px;
            color: black;
        }
        h1 {
            font-size: 24px;
            text-align: left;
            font-family: Montserrat Alternates;
        }
        p {
            font-family: Marmelad;
            font-size: 16px;
            text-align: left;
            font-weight: 700;
        }
    }
    .photo {
        img {
            width: 336px;
            height: 207px;
            border-radius: 65px;
        }
    }
}



 @media screen and (max-device-width: 480px) {
    .home {
        min-height: 65vh;
    }
        .content-home {
            display: flex;
            flex-direction: column-reverse;
            align-content: center;
            flex-wrap: wrap;
            margin-top: 50px;
            margin-bottom: 50px;
        }
        .photo {
            margin-bottom: 50px;
            img {

            }
        }
        .description {
            display: flex;
            margin-right: 0;
            margin-left: 20px;
            img {
                position: absolute;
                top: -30px;
                left: 10px;
                width: 70px;
                height: 60px;
            }
        }
     }

 @media screen and (max-device-width: 320px) {
    .home {
        min-height: 60vh;

    }
    .content-home {
        display: flex;
        flex-direction: column-reverse;
        align-content: center;
        flex-wrap: wrap;
        margin-top: 30px;
        align-items: center;
        margin-bottom: 50px;
    }
    .photo {
        margin-bottom: 30px;
        img {
            width: 280px;
            height: 182px;
        }
    }
    .description {
        display: flex;
        margin-right: 0;
        margin-left: 20px;
        margin-bottom: 70px;
        width: 280px;

    }
 }
