.house {
    background-image: url('../../../public/img/bi2.png');
    background-color: #E8EBE4;
    background-repeat: no-repeat;
    background-size: 100%;
    min-height: 100vh;
    h1 {
        margin-top: 25px;
        margin-left: 230px;
        font-size: 50px;
        line-height: 68px;
        font-family: Prata;
    }
}


.content-photo-main {
    position: absolute;
    top: -12px;
    left: -160px;
    width: 506px;
    height: 319px;
    img {
        border-radius: 50px;
        height: 100%;
        width: 100%;
    }
}
.content-house-desktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}
.card-house {
    max-height: 630px;
    overflow: hidden;
}
.card-house--active {
    overflow: visible;
    min-height: 100vh;
    transition: 0.1s;
}

.content-main {
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin: 41px 0;
    background-color: #D8CFC8;
    width: 1145px;
    height: 300px;
    border-radius: 50px;
}

.desc-house {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 380px;
    top: 25px;
}

.item-house {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    margin-right: 30px;
    h4 {
        font-size: 16px;
        font-weight: 400;
    }
    h4::before {
        content: "⚫"; /* Добавляем символ */
        padding-right: 7px; /* Расстояние от текста до маркеров */
        color: red; /* Красный цвет маркеров */
    }
}


.items-house {
    display: grid;
    grid-template-columns: 215px 215px 240px;
    margin-bottom: 20px;
}

.title-house {
    font-size: 32px;
    font-family: Bitter;
    font-weight: 400;
    line-height: 33px;
    margin-bottom: 35px;
}

.price-menu {
    display: flex;
    align-items: center;
    
    h2 {
        margin-right: 20px;
        font-size: 24px;
    }
    ul {
        position: absolute;
        top: 140px;
        margin-top: 15px;
        background: #ffffff;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.09);
        border-radius: 10px;
        overflow: hidden;
        padding: 10px 0;
        width: 160px;
    }
    li {
        text-align: center;
        font-size: 16px;
        &.active,
        &:hover {
          background: rgba(254, 95, 30, 0.05);
        }

        &.active {
          font-weight: bold;
          color: orange;
        }
    }
}



.price-house {
    height: 69px;
    margin: 0px 15px 0 0;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: flex-start;
    h3 {
        margin-bottom: 3px;
        font-family: Bitter;
        font-size: 22px;
        font-weight: 400;
        text-align: center;
        display: flex;
    }
    span {
        color: green;
        font-size: 24px;
        text-align: center;
    }
}

.content-photo-desc {
    margin-right: 40px;
    width: 80%;
    margin-left: 170px;
    img {
        box-shadow: 0px 5px 5px 0px #0000004D;
        border-radius: 50px;
        width: 362px;
        height: 233px;
        margin-right: 74px;
        margin-bottom: 20px;
        cursor: pointer;
    }
    video {
        box-shadow: 0px 5px 5px 0px #0000004D;
        border-radius: 50px;
        width: 362px;
        height: 233px;
        margin-right: 74px;
        margin-bottom: 20px;
        cursor: pointer;
    }
}

.arrow-down {
    margin-top: 67px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.modal-house-slider {
    .swiper {
        width: 80em;
        height: 55em;
        margin-left: auto;
        margin-right: auto;
        --swiper-navigation-size: 25px;
        .swiper-slide {
            text-align: center;
            font-size: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .swiper-slide img {
            display: block;
            width: 90%;
            height: 90%;
            object-fit: cover;
          }
          .swiper-slide video {
            display: block;
            width: 90%;
            height: 90%;
          }
          .swiper-button-next {
            color: rgb(254, 254, 254);
            }
            .swiper-button-prev {
            color: rgb(254, 254, 254);
            }
            .swiper-pagination-bullet {
                width: 15px;
                height: 15px;
                --swiper-theme-color: #ffffff;
            }
    }
}

.content-house-mobile {
    display: none;
    margin-left: 35px;
    position: relative;
    flex-direction: column;
}
.content-main-mobile {
    border-radius: 50px;
    margin-bottom: 30px;
    background-color: #D8CFC8;
    width: 330px;
    height: 485px; 
    display: flex;
    flex-direction: column;
}
.content-photo-main-mobile {
    img {
        position: absolute;
        top: 0px;
        left: -2px;
        width: 289px;
        height: 182px;
        border-radius: 30px;
    }
    .swiper-wrapper {
        width: 350px;
        height: 182px;
    }
    .swiper {
        width: 289px;
        height: 182px;
        border-radius: 30px;
        --swiper-navigation-size: 25px;

        .swiper-slide {
            width: 289px;
            height: 182px;
            text-align: center;
            font-size: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            }
            .swiper-slide.active {
            width: 289px;
            height: 182px;  
            }
            .swiper-slide img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            }
            .swiper-button-next {
            color: rgb(254, 254, 254);
        }
        }
        
}
.desc-house-mobile {
    position: absolute;
    left: 25px;
    top: 182px;
}
.title-house-mobile {
    margin-bottom: 15px;
}
.items-house-mobile {
    display: grid;
    grid-template-columns: 150px 150px;
    margin-bottom: 0px;
}

.item-house-mobile {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    margin-right: 30px;
    h4 {
        font-size: 16px;
        font-weight: 400;
        
    }
    h4::before {
        content: "⚫"; /* Добавляем символ */
        padding-right: 7px; /* Расстояние от текста до маркеров */
        color: red; /* Красный цвет маркеров */
    }
}



.price-house-mobile {
    border-radius: 15px;
    margin: 5px 15px 0 -10px;
    display: flex;
    align-content: center;
    align-items: flex-start;
    justify-content: center;
    align-items: center;
    h2 {
        font-size: 20px;
    }
    h3 {
        margin-bottom: 3px;
        font-family: Bitter;
        font-size: 11px;
        font-weight: 400;
    }
    span {
        color: green;
        font-size: 13px;
    }
}

.card-houses-content-mobile {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
}
.house {
    h1 {
        text-align: center;
        margin-left: 0px;
        margin-bottom: 20px;
    }
}


.content-photo-desc-mobile {
    margin-right: 40px;
    width: 80%;
    margin-left: 170px;
    img {
        
    }
}




@media (max-width: 1870px) {
    .content-house-desktop {
        display: flex;
        justify-content: space-between;
        margin-left: 80px;
    }
    .content-photo-desc {
        margin-left: 10%;
        img {
            margin-right: 30px;
        }
        video {
            margin-right: 30px;
        }
    }

    
}

@media (max-width: 1760px) {
    .content-house {
        display: flex;
        justify-content: space-between;
        margin-left: 60px;
    }
    .content-photo-desc {  
        display: flex; 
        flex-wrap: wrap;
        min-width: 70%;
        margin-left: 10%;
        img {
            margin-right: 30px;
        }
        video {
            margin-right: 30px;
        }
    }

    
}

@media (max-width: 1680px) {
    .content-house {
        display: flex;
        margin-left: 50px;
    }
    .content-photo-desc {
        display: flex;
        justify-content: start;
        min-width: 90%;
        margin-left: 5%;
    }
}


@media screen and (max-width: 1280px) {
    .modal-house-slider {
        .swiper {
            width: 60em;
            height: 45em;
        }
    }
    .content-photo-main {
        img {
            width: 506px;
            height: 360px;
        }
    }
    .content-main {
        display: flex;
        justify-content: flex-start;
        position: relative;
        margin: 41px 0;
        background-color: #D8CFC8;
        width: 950px;
        height: 333px; 
    }
    .items-house {
        display: grid;
        grid-template-columns: 150px 150px 150px;
    }

    .desc-house {
        left: 400px;
    }
    .content-photo-desc {
        display: flex;
        justify-content: start;
        width: 100%;
        margin-left: 2%;
    }
    .arrow-down {
        margin-top: 30px ;
        cursor: pointer;
    }
    .price-house-card {
        display: flex;
        margin-left: 5px;
        flex-direction: column;
        h2 {
            text-align: center;
        }
    }
    .price-house {
        margin: 5px 0px 0 0;
        h3 {
            margin-bottom: 3px;
            font-family: Bitter;
            font-size: 20px;
            font-weight: 400;
        }
        span {
            color: green;
            font-size: 22px;
        }
    }
    .card-house {
        max-height: 655px;
    }
    
 }

 @media screen and (max-device-width: 1024px)  { 
    
    .content-photo-main {
        img {
            position: absolute;
            width: 407px;
            height: 273px;
            top: 4px;
            left: 21px;
        }
    }
    .content-main {
        display: flex;  
        justify-content: flex-start;
        position: relative;
        margin: 41px 0;
        background-color: #D8CFC8;
        width: 800px;
        height: 300px; 
    }
    .items-house {
        display: grid;
        grid-template-columns: 150px 150px 150px;
    }

    .desc-house {
        left: 290px;
    }
    .content-photo-desc {
        display: flex;
        justify-content: start;
        margin-left: 2%;
        margin-bottom: 10px;
        img {
            width: 262px;
            height: 179px;
        }
        video {
            width: 262px;
            height: 179px;
        }
    }
    .arrow-down {
        margin-top: 30px ;
        cursor: pointer;
    }
    .price-house {
        margin: 10px 0px 0px 0;
    }
    .card-house {
        max-height: 570px;
        overflow: hidden;
    }
    
    .price-house {
        h3 {
            margin-bottom: 3px;
            font-family: Bitter;
            font-size: 18px;
            font-weight: 400;
        }
        span {
            color: green;
            font-size: 20px;
        }
    }
    .title-house {
        margin-bottom: 20px;
    }

 }

 @media screen and (max-device-width: 820px) { 
    .modal-house-slider {
        .swiper {
            width: 45em;
            height: 40em;
        }
    }
    .content-photo-main-mobile {
        img {
            width: 289px;
            height: 182px;
        }
        video {
            width: 289px;
            height: 182px;
        }
        .swiper-wrapper {
            width: 300px;
            height: 200px;
        }
        .swiper {
            width: 289px;
            height: 182px;
            .swiper-slide {
                width: 289px;
                height: 182px;
                }
                .swiper-slide.active {
                width: 289px;
                height: 182px;  
                }
            }
    }
    .content-house-desktop {
        display: none;
    }
    .content-house-mobile { 
        display: flex;
        flex-wrap: wrap;
    }
    .card-houses-content-mobile { 
        margin: 0;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    .card-house {
        max-height: 1000px;
        overflow: hidden;
    }
    .card-house--active {
        overflow: visible;
        min-height: 100vh;
        transition: 0.1s;
    }

    .content-about {
        margin-bottom: 30px;
    }
    .arrow-down {
        display: none;
    }
}
 



@media screen and (max-device-width: 768px) { 
    .modal-house-slider {
        .swiper {
            width: 35em;
            height: 30em;
            margin-left: auto;
            margin-right: auto;
            .swiper-slide {
                text-align: center;
                font-size: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .swiper-slide img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
              .swiper-slide video {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
              .swiper-button-next {
                color: rgb(254, 254, 254);
                }
                .swiper-button-prev {
                color: rgb(254, 254, 254);
                }
        }
    }
    .content-photo-main-mobile {
        img {
            width: 289px;
            height: 182px;
        }
        video {
            width: 289px;
            height: 182px;
        }
        .swiper-wrapper {
            width: 300px;
            height: 200px;
        }
        .swiper {
            width: 289px;
            height: 182px;
            .swiper-slide {
                width: 289px;
                height: 182px;
                }
                .swiper-slide.active {
                width: 289px;
                height: 182px;  
                }
            }
    }

    .content-house-desktop {
        display: none;
    }
    .content-house-mobile { 
        display: flex;
        flex-wrap: wrap;
    }

    .card-house--active {
        overflow: visible;
        min-height: 100vh;
        transition: 0.1s;
    }

    .content-about {
        margin-bottom: 30px;
    }
    .arrow-down {
        display: none;
    }
}




@media screen and (max-device-width: 480px) {
    .modal-house-slider {
        .swiper {
            width: 28em;
            height: 20em;
            margin-left: auto;
            margin-right: auto;
            .swiper-slide {
                text-align: center;
                font-size: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .swiper-slide img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
              .swiper-button-next {
                color: rgb(254, 254, 254);
                }
                .swiper-button-prev {
                color: rgb(254, 254, 254);
                }
        }
    }
    .content-photo-main-mobile {
        img {
            width: 289px;
            height: 182px;
        }
        video {
            width: 289px;
            height: 182px;
        }
        .swiper-wrapper {
            width: 300px;
            height: 200px;
        }
        .swiper {
            width: 289px;
            height: 182px;
            .swiper-slide {
                width: 289px;
                height: 182px;
                }
                .swiper-slide.active {
                width: 289px;
                height: 182px;  
                }
            }
    }
    .title-house-mobile {
        margin-top: 10px;
        h2 {
            font-size: 20px;
        }
    }
    .card-houses-content-mobile {
        justify-content: center;
        margin: 0;
    }
    .content-house-mobile {
        justify-content: center;
        margin: 0;
    }
    .content-house-mobile {
        justify-content: center;
    }
    .content-main-mobile {
        margin-bottom: 15px;
        height: 465px;
    }
    .arrow-down {
        display: flex;
        .material-symbols-outlined {
            font-size: 35px;
        }
    }
    .card-house {
        max-height: 945px;
    }
    .items-house-mobile {
        margin-bottom: 10px;
    }
    .item-house-mobile {
        margin-bottom: 5px;
    }
}
    


@media screen and (max-device-width: 420px) {
    .modal-house-slider {
        .swiper {
            width: 22em;
            height: 18em;
        }
    }
    .item-house-mobile {
        margin-right: 30px;
        h4 {
            font-size: 14px;
        }
    }
}

@media screen and (max-device-width: 320px) {
    .modal-house-slider {
        .swiper {
            width: 18em;
            height: 16em;
        }
    }
    .title-house-mobile {
        margin-top: 10px;
    }
    .card-houses-content-mobile {
        justify-content: center;
        margin: 0;
    }
    .content-house-mobile {
        justify-content: center;
        margin: 0;
    }
    .content-house-mobile {
        justify-content: center;
    }
    .content-main-mobile {
        width: 275px;
        margin-bottom: 15px;
        height: 455px;
    }
    .arrow-down {
        display: flex;
        .material-symbols-outlined {
            font-size: 35px;
        }
    }
    .card-house {
        max-height: 925px;
    }
    .content-photo-main-mobile {
        img {
            width: 260px;
            height: 170px;
        }
        video {
            width: 260px;
            height: 170px;
        }
        .swiper-wrapper {
            width: 300px;
            height: 170px;
        }
        .swiper {
            width: 260px;
            height: 170px;
            .swiper-slide {
                width: 260px;
                height: 170px;
                }
                .swiper-slide.active {
                width: 260px;
                height: 170px;  
                }
            }
    }
    .items-house-mobile {
        display: grid;
        grid-template-columns: 125px 125px;
        margin-bottom: 15px;
    }
    
    .item-house-mobile {
        display: flex;
        margin-bottom: 5px;
        align-items: center;
        margin-right: 30px;
        h4 {
            font-size: 11px;
            font-weight: 400;
            
        }
        h4::before {
            content: "⚫"; /* Добавляем символ */
            padding-right: 7px; /* Расстояние от текста до маркеров */
        }
    }

}
    
    
    
    
    

