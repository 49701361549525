.order {
    background-image: url('../../../public/img/bg4.png');
    background-color: #E8EBE4;
    background-repeat: no-repeat;
    min-height: 529px;
    background-position: left bottom;
    background-size: 100% 35%;
}

.form-order {
    margin-top: 57px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    h1 {
        font-size: 56px;
        margin-bottom: 10px;
    }
    h3 {
        font-size: 24px;   
    }
}

.inputs-order {
    display: flex;
    margin-top: 47px;
    input {
        width: 306px;
        height: 62px;
        border: 1px solid #000000;
        border-radius: 29px;
        margin-right: 112px;
        padding: 15px 36px;
        font-family: Marmelad;
        font-size: 24px;
        color: #7C6958;
    }
}

::-webkit-input-placeholder {
    color: #7C6958;
    font-family: Marmelad;
    font-size: 24px;
  }

  .checkbox-choice {
    margin-top: 55px;
    input {
        width: 24px;
        height: 24px;
    }

    label {
        font-family: Marmelad;
        font-size: 24px;
        margin-right: 36px;
        margin-left: 15px;
    }
  }

.btn-order {
    display: flex;
    margin-top: 41px;
    button {
        width: 342px;
        height: 84px;
        border-radius: 49px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 270px;
        height: 70px;
        background-color: #092B1A;
        color: white;
        text-transform: uppercase;
        font-weight: 400;
        transition: 0.3s;
    }
    button:hover {
        background-color: #05170E;
        color: #FCFBFB;
    }
}