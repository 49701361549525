.about {
    min-height: 100vh;
    background-color: #E8EBE4;
    
}

.content-about {
    display: flex;
    align-items: center;
    margin: 130px 0 134px 250px;
}
.content-about-left {
    margin-right: 150px;
    h1 {
        font-size: 3em;
        text-align: left;
        margin-bottom: 25px;
        font-family: Prata;
    }
    h2 {
        font-size: 24px;
        text-align: left;
        width: 465px;
    }
    .cards {
        margin-top: 81px;
        display: flex;
        width: 550px;
        flex-wrap: wrap;
        justify-content: space-between;
        img {
            width: 112px;
            height: 112px;
            margin-bottom: 13px;
        }

        .card-info {
            text-align: center;
            height: 165px;
            width: 135px;
            margin-right: 25px;
            margin-bottom: 70px;
        }
    }
    

}

.content-about-right {
    position: relative;
    .sup-photo {
        position: absolute;
        top: -287px;
        left: 0px;

    }
    .up-photo {
        position: absolute;
        top: -125px;
        left: 243px;
        z-index: 3;

    }
}



@media (max-width: 1680px) {
    .content-about-right {
        .sup-photo {
            position: absolute;
            top: -287px;
            left: 0px;
            width: 400px;
    
        }
        .up-photo {
            position: absolute;
            top: -125px;
            left: 243px;
            z-index: 3;
            width: 280px;
        }
    }
    .content-about-left {
        margin-right: 5px;
    }
}


@media screen and (max-width: 1280px) {
    .content-about-right {
        .sup-photo {
            position: absolute;
            top: -287px;
            left: 0px;
            width: 400px;
            height: 257px;
    
        }
        .up-photo {
            position: absolute;
            top: -125px;
            left: 243px;
            z-index: 3;
            width: 280px;
            height: 353px;
        }
    }
    .content-about {
        margin: 93px 0 107px 71px;
    }
    
}

@media screen and (max-width: 1220px) {
    .content-about-right {
        display: none;
        }
    .content-about {
        display: flex;
        align-items: center;
        justify-content: center; 
    }
}


@media screen
and (max-device-width: 1024px)  { 
    .content-about-right {
        display: none;
        }
    .content-about {
        display: flex;
        align-items: center;
        justify-content: center;
    }
  
}


@media screen and (max-device-width: 768px)  { 
    .content-about {
        margin: 70px 0 70px 50px;
    }
}



@media screen and (max-device-width: 480px)
   and (orientation: portrait) {
    .content-about {
        margin: 30px 0 50px 0;
    }
    .content-about-left {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
            font-size: 2.2em;

        }
        h2 {
            width: 14em;
            font-size: 22px;
            text-align: center;
            font-family: Bitter;
        }
        .cards {
            display: flex;
            justify-content: space-around;
            width: 85%;
            margin-top: 50px;
            .card-info {
                margin: 0 0px 20px 0;
            }
        }
        
    }
    }
@media screen and (max-device-width: 320px) {
    .content-about {
        margin: 30px 0 50px 0;
    }
    .content-about-left {
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
            font-size: 24px;
        }
        h2 {
            font-size: 16px;
            text-align: center;
            width: 258px;
        }
        .cards {
            display: flex;
            width: 220px;
            margin-top: 50px;
            .card-info {
                height: 105px;
                width: 80px;
                margin: 0 0px 20px 0;
                img {
                    width: 72px;
                    height: 72px;
                }
            }
        }
        
    }
}