.fade-top {
    height: 80px;
    width: 100%;
    background-image: linear-gradient(to bottom, #000000, #E8EBE4);
    opacity: 0.5;
    position: relative;
    
}
.content-galerry-title {
    left: 0px;
    h1 {
        margin: 0px 0px 50px 270px;
        font-size: 50px;
        font-family: Prata;
    }
}



.gallery {
    min-height: 100vh;
    background-image: url('../../../public/img/bg5.png');
    background-color: #E8EBE4;
    background-repeat: no-repeat;
    background-position-x: 90%;
    background-position-y: 90%;
}


.content-gallery {
    .swiper {
        width: 80em;
        height: 55em;
        margin-left: auto;
        margin-right: auto;
        --swiper-navigation-size: 25px;
        .swiper-slide {
            text-align: center;
            font-size: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .swiper-slide img {
            display: block;
            width: 90%;
            height: 90%;
            object-fit: cover;
          }
          .swiper-button-next {
            color: rgb(254, 254, 254);
            }
            .swiper-button-prev {
            color: rgb(254, 254, 254);
            }
    }
}

.gallery-photos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 130px;
    margin-top: 50px;
    img {
        border-radius: 30px;
        width: 100%;
        height: 100%;
        cursor: pointer;
        transition: 0.2s;
        filter: brightness(60%);
        z-index: 1;

    }
    img:hover {
        filter: brightness(100%);
        z-index: 2;
    }
}

.gallery-photo {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 489px;
    height: 315px;
    margin: 15px 252px 15px 0;
}


.gallery-title {
    position: absolute;
    color: #fff;
    font-size: 40px;
    z-index: 2;

}


.gallery-photos-mobile {
    display: none;
}
.gallery-photos-desktop {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

@media (max-width: 1680px) {
    .gallery-photos {
        margin-left: 60px;
        img {
            width: 380px;
            height: 240px;
        }
     }
    .gallery-photo {
        margin: 15px 160px 15px 0;
    }

}

@media screen and (max-width: 1280px) {
    .content-gallery {
        .swiper {
            width: 60em;
            height: 45em;
            margin-left: auto;
            margin-right: auto;
            .swiper-slide {
                text-align: center;
                font-size: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .swiper-slide img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
              .swiper-button-next {
                color: rgb(254, 254, 254);
                }
                .swiper-button-prev {
                color: rgb(254, 254, 254);
                }
        }
    }
    .gallery-photos {
        margin-left: 60px;
        margin-top: 0px;
        img {
            width: 380px;
            height: 240px;
        }
     }
    .gallery-photo {
        margin: 15px 50px 15px 0;
    }
    .content-galerry-title {
        h1 {
            margin: 0px 0px 0px 160px;
        }
    }
}


@media screen and (max-device-width: 1024px)  {
    .content-gallery {
        .swiper {
            width: 50em;
            height: 40em;
            margin-left: auto;
            margin-right: auto;
            .swiper-slide {
                text-align: center;
                font-size: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .swiper-slide img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
              .swiper-button-next {
                color: rgb(254, 254, 254);
                }
                .swiper-button-prev {
                color: rgb(254, 254, 254);
                }
        }
    }
    .gallery-photos {
        margin-left: 10px;
        margin-top: 0px;
     }
    .gallery-photo {
        width: 361px;
        height: 233px;
        margin: 40px 30px 10px 30px;
    }
    .content-galerry-title {
        h1 {
            margin: 0px 0px 0px 0px;
            text-align: center;
        }
    }
}

@media screen and (max-device-width: 820px)  {
    .content-gallery {
        .swiper {
            width: 35em;
            height: 20em;
            margin-left: auto;
            margin-right: auto;
            .swiper-slide {
                text-align: center;
                font-size: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .swiper-slide img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            }
            .swiper-button-next {
            color: rgb(254, 254, 254);
            }
            .swiper-button-prev {
            color: rgb(254, 254, 254);
            }
        }
    }
    .gallery-photos {
        margin-left: 10px;
        margin-top: 0px;
        img {
            width: 264px;
            height: 170px;
        }
     }
    .gallery-photo {
        width: 264px;
        height: 170px;
        margin: 30px 30px 10px 30px;
    }
    .content-galerry-title {
        h1 {
            margin: 0;
            text-align: center;
        }
    }
 }


@media screen and (max-device-width: 768px)  {
    .content-gallery {
        .swiper {
            width: 35em;
            height: 20em;
            margin-left: auto;
            margin-right: auto;
            .swiper-slide {
                text-align: center;
                font-size: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .swiper-slide img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            }
            .swiper-button-next {
            color: rgb(254, 254, 254);
            }
            .swiper-button-prev {
            color: rgb(254, 254, 254);
            }
        }
    }
    .gallery-photos {
        margin-left: 10px;
        margin-top: 0px;
        img {
            width: 264px;
            height: 170px;
        }
     }
    .gallery-photo {
        width: 264px;
        height: 170px;
        margin: 30px 30px 10px 30px;
    }
    .content-galerry-title {
        h1 {
            margin: 0;
            text-align: center;
        }
    }
 }

 @media screen and (max-device-width: 480px) {
    .content-gallery {
        .swiper {
            width: 28em;
            height: 23em;
        }
    }
    
    .gallery {
        min-height: 75vh;
    }
    .content-galerry-title {
        h1 {
            font-size: 2.8em;
        }
    }
    .gallery-photos-mobile {
        max-width: 95%;
        display: flex;
        .swiper {
            width: 97%;
            height: 20em;
            margin-left: 0;
            margin-right: 0;
            .swiper-slide {
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                .gallery-photo {
                    margin: 0px 10px 0;
                    width: 24em;
                    height: 15em;
                    img {
                        display: block;
                        width: 90%;
                        height: 90%;
                        object-fit: cover;
                    }
                    }
                }
                .swiper-button-next {
                color: rgb(0, 0, 0);
                }
                .swiper-button-prev {
                color: rgb(0, 0, 0);
                }
                .swiper-pagination-bullet {
                    width: 13px;
                    height: 13px;
                    --swiper-theme-color: #403f3f;
                }
        }
    }
    .gallery-photos-desktop {
        display: none;
    }
}

@media screen and (max-device-width: 420px) {
    .content-gallery {
        .swiper {
            width: 23em;
            height: 18em;
        }
    }
    .gallery-photos-mobile {
        display: flex;
            .swiper {
                width: 24em;
                height: 20em;
            }
    }
}

@media screen and (max-device-width: 320px) {
    .content-gallery {
        .swiper {
            width: 18em;
            height: 15em;
        }
    }
    .fade-top {
        height: 30px;
    }
    .content-galerry-title {
        h1 {
            font-size: 24px;
        }
    }
    .gallery-photos-mobile {
        display: flex;
        .swiper {
            width: 320px;
            height: 230px;
            margin-left: 0;
            margin-right: 0;
            .swiper-slide {
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                .gallery-photo {
                    margin: 0px 10px 0;
                    width: 320px;
                    height: 190px;
                    img {
                        display: block;
                        width: 90%;
                        height: 90%;
                        object-fit: cover;
                        }
                    }
                }
                .swiper-button-next {
                color: rgb(0, 0, 0);
                }
                .swiper-button-prev {
                color: rgb(0, 0, 0);
                }
                .swiper-pagination-bullet {
                    width: 13px;
                    height: 13px;
                    --swiper-theme-color: #403f3f;
                }
        }
    }
    .gallery-photos-desktop {
        display: none;
    }
 }
