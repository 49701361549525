.menu {
    background-color: #E8EBE4;
    width: 100%;
    min-height: 100vh;
}

.header-menu {
    background-image: url('../../../public/img/bg-menu.png');
    background-size: 100vw 40vh;
    background-repeat: no-repeat;
    width: 100%;
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    h1 {
        color: #fff;
        font-size: 56px;
        font-weight: 700;
    }
}

.navbar-menu {
    top: 0;
    width: 100%;
    position: sticky;
    display: flex;
    align-items: center;
    height: 80px;
    background-color: #7C6958;
    justify-content: center;
    z-index: 10;
    span {
        color: #fff;
        font-size: 40px;
        margin: 0 10px 0 10px;
        transition: 0.4s;
    }
    span:hover {
        color: #092B1A;
    }
}

.nav-menu {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.nav-menu-links {
    margin-left: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    a {
        margin-right: 30px;
    }
    a:last-child {
        margin-right: 0px;
    }
}

.nav-menu-link {
    font-family: Marmelad;
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #fff;
    text-align: center;
    cursor: pointer;
    transition: 0.3s;
}


.nav-menu-link:hover {
    color: #092B1A;
}
.fade-top {
    height: 40px;
    width: 100%;
    background-image: linear-gradient(to bottom, #000000, #E8EBE4);
    opacity: 0.5;
    position: relative;
    
}

.menu-content {
    display: flex;
    width: 100%;
}

.menu-content-left {
    min-width: 45%;
    margin-top: 50px;
    margin-left: 77px;
}

.menu-category {
    h1 {
        padding: 25px 0 25px 0;
        font-size: 56px;
        border-right: solid 3px #092B1A;
        border-top: solid 3px #092B1A;
        border-bottom: solid 3px #092B1A;
    }
}

.menu-card {
    margin: 30px 0 30px 0;
    display: flex;
    justify-content: space-between;
    h2 {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 10px;
    }
    h3 {
        font-weight: 700;
        font-size: 20px;
    }
    p {
        word-wrap: break-word;

    }
}

.menu-card-title {
    width: 70%;
}


.menu-content-right {
    min-width: 45%;
    margin-top: 50px;
    margin-left: 32px;
}





@media screen and (max-device-width: 1280px) {
    .menu-category {
        width: 95%;
    }
    .nav-menu-link {
        font-size: 16px;
    }
}

@media screen and (max-device-width: 1024px) {
    .menu-category {
        h1 {
            padding: 25px 0 25px 0;
            font-size: 40px;
            border-right: solid 3px #092B1A;
            border-top: solid 3px #092B1A;
            border-bottom: solid 3px #092B1A;
        }
    }
    .nav-menu-link {
        font-size: 14px;
        margin-right: 15px;
    }
    
    .menu-card {
        margin: 30px 0 30px 0;
        display: flex;
        justify-content: space-between;
        h2 {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 10px;
        }
        h3 {
            font-weight: 700;
            font-size: 18px;
        }
        p {
            font-size: 12px;
        }
    }
    
 
 }

 @media screen and (max-device-width: 768px) {
    .header-menu {
        background-size: 100vw 20vh;
        height: 15vh;
        align-items: center;
        justify-content: center;
        h1 {
            color: #fff;
            font-size: 56px;
            font-weight: 700;
        }
    }
   
    .nav-menu-links {
        margin-left: 10px;
        margin-right: 15px;
    }
    .nav-menu-link {
        font-size: 13px;
        margin-right: 15px;
    }
    .menu-category {
        width: 90%;
        h1 {
            padding: 25px 0 25px 0;
            font-size: 30px;
            border-right: solid 3px #092B1A;
            border-top: solid 3px #092B1A;
            border-bottom: solid 3px #092B1A;
        }
    }
    
    .menu-card {
        margin: 30px 0 30px 0;
        display: flex;
        justify-content: space-between;
        h2 {
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 10px;
        }
        h3 {
            font-weight: 700;
            font-size: 14px;
        }
        p {
            font-size: 10px;
        }
    }
    
    .menu-card-title {
        width: 70%;
    }
 }

 @media screen and (max-device-width: 480px) {
    .navbar-menu-content {
        background-color: #7C6958;
        width: 60vw;
        height: 100vh;
        display: flex;
    }
    .navbar-menu {
        justify-content: flex-end;
        height: 60px;
        .material-symbols-outlined {
            font-size: 40px;
        }
    }
    .burger-menu-content {
        position: fixed;
        left: -100%;
        right: 0;
        bottom: 0;   
        top: 0;
        height: 100vh;
        width: 100vw;
        z-index: 10;
        transition: Left 0.4s;
    }
    .burger-menu-content.active {
        left: 0;
    }
    .nav-menu-links {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        flex-direction: column;
        justify-content: center;
        a {
            margin-right: 0px;
        }
        a:last-child {
            margin-right: 0px;
        }
    }
    
    .nav-menu-link {
        color: 12px;
        margin-right: 0px;
        margin-bottom: 30px;
        font-size: 24px;
        color: #fff;
        font-weight: 400;
        .active {
            border-bottom: solid 3px #E8EBE4;
            padding-bottom: 5px;
            color: #E8EBE4;
            font-size: 24px;
        }
    }
    .mobile-btn {
        display: block;
        position: absolute;
        left: 1em;
        top: 0.7em;
        cursor: pointer;
        z-index: 99;
        h1 {
            
            color: #fff;
            font-family: Marmelad;
            font-size: 25px;
        }
    }
    .right-column {
        display: none;
    }
    .logo {
        margin-bottom: 30px;
    }

    .navbar-menu {
        .material-symbols-outlined {
            font-size: 25px;
        }
    }
    .menu-content-left {
        max-width: 45%;
        margin-top: 50px;
        margin-left: 20px;
    }
    .menu-content-right {
        max-width: 45%;
        margin-top: 50px;
        margin-left: 20px;
    }
    .header-menu {
        background-size: 100vw 20vh;
        height: 15vh;
        align-items: center;
        justify-content: center;
        h1 {
            color: #fff;
            font-size: 56px;
            font-weight: 700;
        }
    }
    .menu-content {
        display: flex;
        flex-direction: column;
    }
    .menu-content-left {
        max-width: 90%;
        margin-top: 50px;
        margin-left: 32px;
    }
    .menu-content-right {
        max-width: 90%;
        margin-top: 50px;
        margin-left: 32px;
    }
    .menu-category {
        width: 100%;
        h1 {
            padding: 25px 0 25px 0;
            font-size: 30px;
            border-right: solid 3px #092B1A;
            border-top: solid 3px #092B1A;
            border-bottom: solid 3px #092B1A;
        }
    }
    
    .menu-card {
        margin: 30px 0 30px 0;
        h2 {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 10px;
        }
        h3 {
            font-weight: 700;
            font-size: 14px;
        }
        p {
            font-size: 14px;
        }
    }
}
    

 @media screen and (max-device-width: 320px) {
    .navbar-menu-content {
        display: flex;
        justify-content: center;
        background-color: #7C6958;
        width: 60vw;
        height: 100vh;
        display: flex;
    }
    .navbar-menu {
        justify-content: flex-end;
        height: 60px;
        .material-symbols-outlined {
            font-size: 40px;
        }
    }
    .burger-menu-content {
        position: fixed;
        left: -100%;
        right: 0;
        bottom: 0;   
        top: 0;
        height: 100vh;
        width: 100vw;
        z-index: 10;
        transition: Left 0.4s;
    }
    .burger-menu-content.active {
        left: 0;
    }
    .nav-menu-links {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        flex-direction: column;
        justify-content: center;
    }
    .nav-menu-link {
        margin-right: 0px;
        margin-bottom: 25px;
        font-size: 20px;
        color: #fff;
        font-weight: 400;
        .active {
            border-bottom: solid 3px #E8EBE4;
            padding-bottom: 5px;
            color: #E8EBE4;
            font-size: 24px;
        }
    }
    .mobile-btn {
        display: block;
        position: absolute;
        left: 1em;
        top: 0.7em;
        cursor: pointer;
        z-index: 99;
    }
    .menu-content {
        flex-direction: column;
    }
    .menu-content-left {
        max-width: 95%;
        margin-top: 50px;
        margin-left: 20px;
    }
    .menu-content-right {
        max-width: 95%;
        margin-top: 50px;
        margin-left: 20px;
    }
    .header-menu {
        background-size: 100vw 20vh;
        height: 15vh;
        align-items: center;
        justify-content: center;
        h1 {
            color: #fff;
            font-size: 56px;
            font-weight: 700;
        }
    }
    
   
    .menu-category {
        width: 90%;
        h1 {
            padding: 25px 0 25px 0;
            font-size: 30px;
            border-right: solid 3px #092B1A;
            border-top: solid 3px #092B1A;
            border-bottom: solid 3px #092B1A;
        }
    }
    .menu-card {
        margin: 30px 0 30px 0;
        display: flex;
        justify-content: space-between;
        h2 {
            font-size: 13px;
            font-weight: 700;
            margin-bottom: 10px;
        }
        h3 {
            font-weight: 700;
            font-size: 12px;
        }
        p {
            font-size: 12px;
        }
        
    }
 }