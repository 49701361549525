.entert {
    min-height: 100vh;
    background-image: url('../../../public/img/bg4.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position:  bottom;
    background-color: #E8EBE4;;
}
.content-entert {
    h1 {
        font-size: 3.5em;
        margin: 61px 0px 32px 230px;
        font-family: Prata;
    }
}

.card-entert {
    display: flex;
    flex-direction: column;
    width: 280px;
    height: 350px;
    position: relative;
    justify-content: center;
    margin-right: 75px;

    .price-entert {
        display: flex;
        border: solid 1px black;
        width: 265px;
        height: 54px;
        border-radius: 24px;
        justify-content: center;
        align-items: center;
        font-family: Bitter;
        font-size: 24px;
        margin-bottom: 13px;
    }
    .ellipse-entert {
        width: 126px;
        height: 126px;
        margin-bottom: 38px;
    }
    .item-entert {
        position: absolute;
        width: 188px;
        height: 172px;
        left: 30px;
        top: 26px;
    }
    h2 {
        font-size: 24px;
        font-family: Bitter;
        text-align: center;

    }
    .material-symbols-outlined {
    position: absolute;
    left: 210px;
    top: 0;
    cursor: pointer;
    font-variation-settings:
    'FILL' 0,
    'wght' 700,
    'GRAD' 200,
    'opsz' 48
    }
}
.tooltip {
    position: absolute;
    background-color: #E8EBE4;
    color: #000000;
    left: 50%;
    bottom: 100%;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    text-transform: none;
    padding: 20px 18px 18px 22px;
    border-radius: 12px;
    display: block;
    width: 256px;
    z-index: 5;
    transform: translateX(-50%) ; 
    display: none; 
    border: solid 1px #000;
}

.material-symbols-outlined:hover + .tooltip,
.material-symbols-outlined:focus + .tooltip {
  display: block;
}

.cards-entert {
    margin: 0px 0px 101px 230px ;
    display: flex;
    flex-wrap: wrap;
}

@media (max-width: 1680px) {
    .card-entert {
        margin-right: 30px;
    }
}



@media screen and (max-width: 1280px) {
    .cards-entert {
        
        width: 95%;
        margin: 0px 0px 101px 0px ;
        justify-content: center;
    }
}


@media screen and (max-device-width: 1024px)  {
    .content-entert {
        margin-bottom: 60px;
        h1 {
            font-size: 56px;
            text-align: center;
            margin: 20px 0 20px 0;
        }
    }
    .cards-entert {
        margin: 0px ;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .card-entert {
        display: flex;
        flex-direction: column;
        width: 200px;
        height: 258px;
        position: relative;
        justify-content: center;
        margin: 0 60px 30px 20px;
        .ellipse-entert {
            width: 100px;
            height: 100px;
            margin-bottom: 29px;
        }
        .item-entert {
            position: absolute;
            width: 130px;
            height: 120px;
            left: 25px;
            top: 7px;
        }
        .price-entert {
            width: 200px;
            font-size: 20px;
        }
        .material-symbols-outlined {
            left: 170px;
        }
    }
}

@media screen and (max-device-width: 820px)  {
    .content-entert {
        margin-bottom: 80px;
        h1 {
            font-size: 56px;
            text-align: center;
            margin: 20px 0 20px;
        }
    }
    .cards-entert {
        width: 100%;
        margin: 0px 0px 0 0px ;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .card-entert {
        display: flex;
        flex-direction: column;
        width: 200px;
        height: 258px;
        position: relative;
        justify-content: center;
        margin: 0 0 35px 20px;
        .ellipse-entert {
            width: 100px;
            height: 100px;
            margin-bottom: 29px;
        }
        .item-entert {
            position: absolute;
            width: 130px;
            height: 120px;
            left: 30px;
            top: 17px;
        }
        .price-entert {
            width: 190px;
            font-size: 18px;
        }
        .material-symbols-outlined {
            left: 140px;
        }
    }
    .tooltip {
        left: 50px;
        bottom: 100%;
        font-weight: 400;
        text-transform: none;
        padding: 20px 18px 18px 22px;
        border-radius: 12px;
        width: 256px;
        z-index: 5;
        transform: translateX(-50%); 
        display: none; 
        border: solid 1px #000;
    }

 }


@media screen and (max-device-width: 768px)  {
    .content-entert {
        margin-bottom: 80px;
        h1 {
            font-size: 56px;
            text-align: center;
            margin: 20px 0 20px;
        }
    }
    .cards-entert {
        width: 100%;
        margin: 0px 0px 0 0px ;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .card-entert {
        display: flex;
        flex-direction: column;
        width: 200px;
        height: 258px;
        position: relative;
        justify-content: center;
        margin: 0 0 35px 20px;
        .ellipse-entert {
            width: 100px;
            height: 100px;
            margin-bottom: 29px;
        }
        .item-entert {
            position: absolute;
            width: 130px;
            height: 120px;
            left: 30px;
            top: 17px;
        }
        .price-entert {
            width: 190px;
            font-size: 18px;
        }
        .material-symbols-outlined {
            left: 140px;
        }
    }

 }

 @media screen and (max-device-width: 480px) {
    .tooltip {
        left: 80px;
        bottom: 100%;
        padding: 10px 9px 9px 11px;
        width: 200px;
    }
    .content-entert {
        h1 {
            font-size: 2.2em;
        }
    }
        .cards-entert {
            margin: 0;
            justify-content: space-around;

        }
        .card-entert {
            width: 175px;
            margin: 0 0 40px 10px;
        }
        .card-entert {
            h2 {
                font-size: 20px;
                text-align: center;
            }
            .price-entert {
                width: 165px;
                font-size: 16px;
                margin-bottom: 3px;
             }
        }
        
        

     }

@media screen and (max-device-width: 320px) {
    .tooltip {
        left: 60px;
        bottom: 100%;
        padding: 10px 9px 9px 11px;
        width: 48vw;
        font-size: 12px;
    }
    .content-entert {
        h1 {
            font-size: 2.2em;
        }
    }
    .card-entert {
        margin: 0 0 30px 0;
        width: 118px;
        height: 200px;
        .ellipse-entert {
            width: 62px;
            height: 62px;
        }
        .item-entert {
            width: 93px;
            height: 85px;
            left: 6px;
            top: 20px;
        }
        .price-entert {
            width: 125px;
            height: 25px;
            font-size: 12px;
            font-family: Bitter;
            margin-bottom: 3px;
         }
         h2 {
            font-size: 16px;
            text-align: center;
        }
        .material-symbols-outlined {
            left: 100px;
        }
    }
    
}