.rest {
    min-height: 100vh;
    background-image: url('../../../public/img/bg3.png');
    background-repeat: no-repeat;
    background-color: #E8EBE4;
    background-position-y: 65%;
}


.content-rest {
    margin-left: 219px;
    margin-top: 70px;
    display: flex;
}

.column {
    margin-top: 50px;
    height: 527px;
    width: 17px;
    background-color: #092B1A;
    margin-right: 50px;
}

.content-rest-left {
    margin-top: 70px;
    h1 {
        font-size: 52px;
        margin-bottom: 11px;
        font-family: Prata;
    }
    h3 {
        font-family: Bitter;
        font-size: 24px;
        margin-bottom: 3px;
    }
    ul {
        font-family: Bitter;
        font-size: 24px;
        li {
            position: relative;
            display: flex;
            font-size: 16px;
            margin-left: 35px;
        }
        li::before {
            position: absolute;
            content: "";
            left: -10px;
            top: 5px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #05170E;
        } 
        li:first-child {
            margin-top: 7px;
        }
    }
    h2 {
        margin-top: 111px;
        font-size: 32px;
        font-family: Bitter;
    }
}

.content-rest-right {
    margin-left: 50px;
    img {
        width: 500px;
        height: 500px;
        border-radius: 20px;
    }
    .active-ellipse {
        width: 22px;
        height: 22px;
    }
}

.slider {
    margin-left: 208px;
    margin-top: 20px;
    flex-direction: column;
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
        .swiper {
            width: 800px;
            height: 570px;
            margin-left: auto;
            margin-right: auto;
            --swiper-navigation-size: 25px;
            .swiper-slide {
                text-align: center;
                font-size: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    display: block;
                    width: 85%;
                    height: 85%;
                    object-fit: cover;
                  }
              }
              .swiper-button-next {
                color: rgb(0, 0, 0);
            }
            .swiper-button-prev {
                color: rgb(0, 0, 0);
            }
            .swiper-pagination-bullet {
                width: 20px;
                height: 20px;
                --swiper-theme-color: #909090;
            }
    }
    .material-symbols-outlined {
        height: 24px;
    }
}
.slider-photos {
    .swiper {
        width: 80em;
        height: 55em;
        margin-left: auto;
        margin-right: auto;
        --swiper-navigation-size: 50px;
        .swiper-slide {
            text-align: center;
            font-size: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .swiper-slide img {
            display: block;
            width: 90%;
            height: 90%;
            object-fit: cover;
          }
          .swiper-button-next {
            color: rgb(254, 254, 254);
            }
            .swiper-button-prev {
            color: rgb(254, 254, 254);
            }
            .swiper-pagination-bullet {
                width: 15px;
                height: 15px;
                --swiper-theme-color: #ffffff;
            }
            

    }
    
}

.btn-menu {
    display: flex;
    justify-content: center;
    margin-top: 121px;
    button {
        width: 342px;
        height: 84px;
        border-radius: 49px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 270px;
        height: 70px;
        background-color: #092B1A;
        color: white;
        text-transform: uppercase;
        font-weight: 400;
        transition: 0.3s;
        font-family: Bitter;
    }
    button:hover {
        background-color: #05170E;
        color: #FCFBFB;
    }
}

@media (max-width: 1850px) {
    .slider {
        margin-left: 0px;
        margin-top: 20px;
        flex-direction: column;
        display: flex;
        align-items: center;
        display: flex;
        align-items: center;
        margin-bottom: 30px;
            
            .swiper {
                width: 900px;
                height: 600px;
            }
            .swiper-slide img {
                display: block;
                width: 95%;
                height: 95%;
                object-fit: cover;
              }
        }
        .slider-photos {
            .swiper {
                width: 80em;
                height: 55em;
            }
        }
 }


@media (max-width: 1680px) { 
    .content-rest-right {
        margin-left: 0px;
    }
    .column {
        margin-top: 50px;
        margin-right: 30px;

    }
    
    .slider {
        margin-left: 0px;
        margin-top: 20px;
        flex-direction: column;
        display: flex;
        align-items: center;
        display: flex;
        align-items: center;
        margin-bottom: 30px;
            
            .swiper {
                margin-top: 50px;
                width: 700px;
                height: 550px;
            }
        }
        .slider-photos {
            .swiper {
                width: 80em;
                height: 55em;
            }
        }
}

@media (max-width: 1440px) {
    .content-rest-right {
        width: 100%;
    }
  
   
    .slider {
        margin-left: 0px;
        margin-top: 20px;
        flex-direction: column;
        display: flex;
        align-items: center;
        display: flex;
        align-items: center;
        margin-bottom: 30px;
            .swiper {
                margin-top: 50px;
                width: 650px;
                height: 500px;
            }
        }
        .slider-photos {
            .swiper {
                width: 80em;
                height: 55em;
            }
        }
        
 }



@media screen and (max-width: 1280px) {
    .content-rest {
        margin-left: 50px;
        margin-top: 20px;
    }
    .slider {
        margin-left: 100px;
        margin-top: 20px;
        flex-direction: column;
        display: flex;
        align-items: center;
        display: flex;
        align-items: center;
        margin-bottom: 30px;
            
            .swiper {
                margin-top: 50px;
                width: 550px;
                height: 400px;
                margin-left: auto;
                margin-right: auto;
                .swiper-slide {
                    text-align: center;
                    font-size: 18px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }
                  .swiper-slide img {
                    display: block;
                    width: 80%;
                    height: 80%;
                    object-fit: cover;
                  }
                  .swiper-button-next {
                    color: rgb(0, 0, 0);
                }
                .swiper-button-prev {
                    color: rgb(0, 0, 0);
                }
                .swiper-pagination-bullet {
                    width: 20px;
                    height: 20px;
                    --swiper-theme-color: #909090;
                }
              }
        } 
        .slider-photos {
            .swiper {
                width: 60em;
                height: 45em;
                margin-left: auto;
                margin-right: auto;
                .swiper-slide {
                    text-align: center;
                    font-size: 18px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }
                  .swiper-slide img {
                    border-radius: 5px;
                    display: block;
                    width: 90%;
                    height: 90%;
                    object-fit: cover;
                  }
                  .swiper-button-next {
                    color: rgb(254, 254, 254);
                    }
                    .swiper-button-prev {
                    color: rgb(254, 254, 254);
                    }
            }
        }
}

@media screen and (max-device-width: 1024px)  {
    .slider {
        margin-left: 15px;
        display: flex;
        align-items: center;
        margin-bottom: 30px;
           
            .swiper {
                margin-top: 50px;
                width: 500px;
                height: 380px;
                margin-left: auto;
                margin-right: auto;
                .swiper-slide {
                    text-align: center;
                    font-size: 18px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }
                  .swiper-slide img {
                    display: block;
                    width: 80%;
                    height: 80%;
                    object-fit: cover;
                  }
                  .swiper-button-next {
                    color: rgb(0, 0, 0);
                }
                .swiper-button-prev {
                    color: rgb(0, 0, 0);
                }
                .swiper-pagination-bullet {
                    width: 20px;
                    height: 20px;
                    --swiper-theme-color: #909090;
                }
            }
    }
    .slider-photos {
        .swiper {
            width: 60em;
            height: 45em;
            margin-left: auto;
            margin-right: auto;
            .swiper-slide {
                text-align: center;
                font-size: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .swiper-slide img {
                display: block;
                width: 90%;
                height: 90%;
                object-fit: cover;
              }
              .swiper-button-next {
                color: rgb(254, 254, 254);
                }
                .swiper-button-prev {
                color: rgb(254, 254, 254);
                }
        }
    }
    .content-rest-left {
        h2 {
            margin-top: 70px;
            font-size: 28px;
        }
    }
    .column {
        height: 480px;
    }
}

@media screen and (max-device-width: 820px)  {
    .slider {
        margin-left: 15px;
        margin-top: 50px;
        display: flex;
        align-items: center;
        margin-bottom: 30px;
            .swiper {
                width: 350px;
                height: 320px;
                margin-left: auto;
                margin-right: auto;
                .swiper-slide {
                    width: 300px;
                    height: 300px;
                    text-align: center;
                    font-size: 18px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }
                  .swiper-slide img {
                        display: block;
                        width: 75%;
                        height: 87%;
                        object-fit: cover;
                }
                  .swiper-button-next {
                    color: rgb(0, 0, 0);
                }
                .swiper-button-prev {
                    color: rgb(0, 0, 0);
                }
                .swiper-pagination-bullet {
                    width: 20px;
                    height: 20px;
                    --swiper-theme-color: #909090;
                }
            }
    }
    .slider-photos {
        .swiper {
            width: 45em;
            height: 40em;
            margin-left: auto;
            margin-right: auto;
            .swiper-slide {
                width: 45em;
                height: 35em;
                text-align: center;
                font-size: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .swiper-slide img {
                display: block;
                width: 90%;
                height: 90%;
                object-fit: cover;
              }
              .swiper-button-next {
                color: rgb(254, 254, 254);
                }
                .swiper-button-prev {
                color: rgb(254, 254, 254);
                }
        }
    }
    .content-rest-left {
        h2 {
            margin-top: 70px;
            font-size: 25px;
        }
    }
    .column {
        height: 480px;
    }
    .btn-menu {
        margin-top: 40px;
    }
}



@media screen and (max-device-width: 768px)  {
    .slider {
        margin-left: 15px;
        margin-top: 50px;
        display: flex;
        align-items: center;
        margin-bottom: 30px;
            .swiper {
                width: 350px;
                height: 320px;
                margin-left: auto;
                margin-right: auto;
                .swiper-slide {
                    width: 300px;
                    height: 300px;
                    text-align: center;
                    font-size: 18px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }
                  .swiper-slide{
                    img {
                        display: block;
                        width: 75%;
                        height: 87%;
                        object-fit: cover;
                    }
                }
                  .swiper-button-next {
                    color: rgb(0, 0, 0);
                }
                .swiper-button-prev {
                    color: rgb(0, 0, 0);
                }
                .swiper-pagination-bullet {
                    width: 20px;
                    height: 20px;
                    --swiper-theme-color: #909090;
                }
            }
    }
    .slider-photos {
        .swiper {
            width: 35em;
            height: 35em;
            margin-left: auto;
            margin-right: auto;
            .swiper-slide {
                height: 30em;
                text-align: center;
                font-size: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .swiper-slide img {
                display: block;
                width: 100%;
                height: 95%;
                object-fit: cover;
              }
              .swiper-button-next {
                color: rgb(254, 254, 254);
                }
                .swiper-button-prev {
                color: rgb(254, 254, 254);
                }
                .swiper-pagination-bullet {
                    width: 20px;
                    height: 20px;
                    --swiper-theme-color: #f8f8f8;
                }
        }
    }
    .content-rest-left {
        h2 {
            margin-top: 70px;
            font-size: 25px;
        }
    }
    .column {
        height: 480px;
    }
    .btn-menu {
        margin-top: 40px;
    }
 }

 @media screen and (max-device-width: 480px) {
    .content-rest {
            display: grid;
            grid-template-columns: 40px 430px;
            grid-template-rows: 320px;
            grid-template-areas: 
                "column left"
                "column right";
            margin-top: 20px;
            margin-left: 0;
            flex-direction: column;
        }
        .content-rest-left {
            grid-area: left;
            margin-top: 20px;
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            flex-direction: column;
            width: 85%;
            h1 {
                font-size: 24px;
            }
            h3 {
                font-size: 16px;
            }
            ul {
                font-size: 16px;
            }
            h2 {
                font-size: 20px;
                margin-top: 10px;
            }
        }
        .content-rest-right {
            grid-area: right;
            display: flex;
        }

    .column {
        grid-area: column;
        margin-left: 25px;
        height: 550px;
        }
    .slider {
        width: 90%;
        margin-left: 15px;
        margin-bottom: 30px;
        height: 310px;
        margin-top: 0px;
        display: flex;
        align-items: center;
            .swiper {
                width: 24em;
                height: 260px;
                margin-left: auto;
                margin-right: auto;
                .swiper-slide {
                    width: 24em;
                    height: 250px;
                    text-align: center;
                    font-size: 18px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    }
                    .swiper-slide{
                    img {
                        display: block;
                        width: 75%;
                        height: 75%;
                        object-fit: cover;
                    }
                }
                    .swiper-button-next {
                        font-size: 44px;
                    color: rgb(0, 0, 0);
                }
                .swiper-button-prev {
                    color: rgb(0, 0, 0);
                }
                .swiper-pagination-bullet {
                    width: 20px;
                    height: 20px;
                    --swiper-theme-color: #909090;
                }
            }
        }
        .slider-photos {
            .swiper {
                width: 28em;
                height: 20em;
                .swiper-slide {
                    height: 20em;
                    width: 28em;
                }
                .swiper-slide img {
                    display: block;
                    width: 100%;
                    height: 95%;
                    object-fit: cover;
                  }
                  .swiper-button-next {
                    color: rgb(254, 254, 254);
                    }
                    .swiper-button-prev {
                    color: rgb(254, 254, 254);
                    }
                    .swiper-pagination-bullet {
                        width: 20px;
                        height: 20px;
                        --swiper-theme-color: #f8f8f8;
                    }
            }
            
        }
    }
    @media screen and (max-device-width: 425px) {
        .content-rest {
            grid-template-columns: 35px 325px;
            grid-template-rows: 320px;
        }
        .content-rest-right {
    
        }
        .btn-menu {
            margin-top: 5px;
        }
        .slider {
           width: 100%;
                .swiper {
                width: 100%;
                .swiper-slide {
                    width: 100%;
                }
        }
    
        }
        .slider-photos {
            .swiper {
                width: 24em;
                height: 20em;
            }
            .swiper-slide {
                height: 20em;
                width: 28em;
            }
        }
    }
@media screen and (max-device-width: 420px) {
    .content-rest {
        grid-template-columns: 35px 325px;
        grid-template-rows: 320px;
    }
    .content-rest-right {
        width: 100%;
    }
    .slider {
       width: 45%;
            .swiper {
            width: 19em;
            .swiper-slide {
                width: 19em;
            }
    }

    }
    .slider-photos {
        .swiper {
            width: 24em;
            height: 20em;
            .swiper-slide {
                height: 20em;
                width: 28em;
            }
            .swiper-slide img {
                display: block;
                width: 100%;
                height: 95%;
                object-fit: cover;
              }
              .swiper-button-next {
                color: rgb(254, 254, 254);
                }
                .swiper-button-prev {
                color: rgb(254, 254, 254);
                }
                .swiper-pagination-bullet {
                    width: 20px;
                    height: 20px;
                    --swiper-theme-color: #f8f8f8;
                }
        }
        
    }

}

@media screen and (max-device-width: 320px) {
    .rest {
        min-height: 80vh;
    }
    .btn-menu {
        margin-bottom: 30px;
        button {
            width: 200px;
            height: 49px;
        }
        
    }
    .content-rest {
        grid-template-columns: 40px 270px;
        grid-template-rows: 365px;
    }
    .column {
        grid-area: column;
        margin-left: 17px;
        height: 520px;
    }
    .content-rest-left {
        h2 {
            font-size: 16px;
            font-family: Bitter;
        }
     }
    .slider {
        margin-left: 5px;
        margin-bottom: 5px;
        height: 285px;
        margin-top: 0px;
        display: flex;
        align-items: center;
        margin-bottom: 30px;
            .swiper {
                width: 275px;
                height: 240px;
                margin-left: auto;
                margin-right: auto;
                .swiper-pagination-bullet {
                    width: 10px;
                    height: 10px;
                    --swiper-theme-color: #909090;
                }
                .swiper-slide {
                    width: 275px;
                    height: 240px;
                    text-align: center;
                    font-size: 18px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    }
                    .swiper-slide{
                    img {
                        display: block;
                        width: 75%;
                        height: 75%;
                        object-fit: cover;
                    }
                    
                }
            
        }
    }
    .slider-photos {
        .swiper {
            width: 21em;
            height: 18em;
            .swiper-slide {
                height: 16em;
                width: 21em;
            }
            .swiper-slide img {
                height: 90%;
                width: 90%;
            }
            
        }
       
        
    }
            
}