.modal {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.6);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
    z-index: 99;
    .material-symbols-outlined {
      z-index: 99;
      cursor: pointer;
      position: absolute;
      top: 40px;
      right: 70px;
      color: #fff;
      font-size: 40px;
    }
}

.modal.active {
    opacity: 1;
    pointer-events: all;
}

.modal-content {
}

@media screen and (max-width: 1280px) { 
  .modal {
    height: 100vh;
    .material-symbols-outlined {
      top: 33px;
      right: 22px;
    }
  }

}

@media screen and (max-width: 480px) {
  .modal {
    height: 100vh;
  }
}

@media screen and (max-width: 320px) {
  
}


