.nav {
    position: sticky;
    top: 0;
    z-index: 99;
}

.header-nav {
    display: flex;
    align-items: center;
    height: 112px;
    background-color: #7C6958;
    justify-content: space-between;
    max-width: 100%;
}

.logo {
    cursor: pointer;
    margin-left: 46px;
}

.header-nav-links {
    display: flex;
    align-items: center;
    width: 75vw;
    justify-content: space-between;
    text-transform: uppercase;
    font-weight: 400;
    list-style: none;
}
.header-nav-link {
    font-family: Marmelad;
    font-size: 20px;
    transition: 0.3s;
    cursor: pointer;
    .active {
        color: #E8EBE4;
        font-size: 20px;
    }
    .active-btn {
        background-color: #05170E;
        color: #FCFBFB;
    }

}
.header-nav-link:hover {
    color: #E8EBE4;
}
nav {
    display: flex;
    align-items: center;
    position: relative;
}


.btn-nav-order {
    background-color: #092B1A;
    color: #FCFBFB;
    width: 280px;
    height: 112px;
    text-transform: uppercase;
    font-weight: 700;
    z-index: 10;
}

.btn-nav-order:hover {
    background-color: #05170E;
    color: #FCFBFB;
    
}


.right-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
    min-height: 100vh;
    background-color: #FCFBFB;
    position: absolute;
    right: 0px;
    p {
        font-family: Marmelad;
        transform-origin: top left;
        transform: rotate(-90deg);
        width: 450px;
        position: absolute;
        left: 35px;
        font-size: 16px;
        font-weight: 400;
        top: 830.2px;
        text-align: left;
    }
}

.icons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .inst-icon {
        margin: 30px 0px 30px 0;
    }
    
}
.mobile-btn {
    display: none;
}

@media (max-width: 1680px) {
    .right-column {
        width: 75px;
        p {
            font-size: 16px;
            top: 630px;
            left: 25px;
        }
    }
}


@media screen and (max-width: 1280px) {
    .header-nav-links {
        display: flex;
        justify-content: space-between;
        text-transform: uppercase;
        font-weight: 700;
        list-style: none;
        width: 55vw;
    }
    .header-nav-link {
        white-space: nowrap;
        margin-right: 40px;
    }
    .logo {
        margin-right: 50px;
    }
}




@media screen and (max-device-width: 1024px)  {
    .header-nav-links {
        display: flex;
        width: 897px;
        justify-content: space-between;
        text-transform: uppercase;
        font-weight: 700;
        list-style: none;
        width: 65vw;
    }
    .header-nav-link {
        color: 12px;
        margin-right: 30px;
    }
    .btn-nav-order {
        font-size: 14px;
        width: 156px;
    }
    .right-column {
        width: 60px;
        p {
            font-family: Marmelad;
            transform-origin: top left;
            transform: rotate(-90deg);
            width: 450px;
            left: 10px;
            top: 600px;
            
        }
     }
    
}

@media screen and (max-device-width: 820px)  {
    .btn-nav-order {
        font-size: 14px;
        width: 156px;
        height: 82px!important;
    }
    .header-nav {
        position: sticky;
        display: flex;
        justify-content: space-between;
        height: 82px!important;
    }

    .header-nav-links {
        display: flex;
        width: 768px;
        justify-content: space-between;
        text-transform: uppercase;
        font-weight: 700;
        list-style: none;
        width: 65vw;
    }
    .header-nav-link {
        color: 12px;
        margin-right: 30px;
        margin-bottom: 0px;
        font-size: 14px;
        font-weight: 400;
        font-family: Marmelad;
        .active {
            color: #E8EBE4;
            font-size: 16px;
        }
        
    }
    .header-nav-link:hover {
        color: #E8EBE4;
        transform: scale(1);
    }
    .btn-nav-order {
        font-size: 16px;
        font-family: Marmelad;
        font-weight: 400;
        width: 156px;
    }

    .logo {
        margin: 0px 25px 0 10px;
        img {
            margin: 0px;
            width: 59px!important;
            height: 59px!important;
        }
    }

    .right-column {
        display: none;
    }

}


@media screen and (max-device-width: 768px)  {
    // .navbar-menu {
    //     background-color: #7C6958;
    //     z-index: 10;
    //     transition: Left 0.4s;
    //     .header-nav-links {
           
    //     }
    // }


    // .header-nav-link {
    //     margin-bottom: 30px;
    //     font-size: 24px;
    //     color: #000;
    //     font-weight: 400;
    // }
    // .nav {
    //     position: sticky;
    // }
    // .mobile-btn {
    //     display: none;
    //     position: absolute;
    //     right: 424px;
    //     top: 24px;
    //     cursor: pointer;
    //     z-index: 11;
    // }
    // .header-nav-links {
    //     display: flex;
    // }
    // .header-nav-link {
    //     color: 12px;
    //     margin-right: 15px;
    // }

    .btn-nav-order {
        font-size: 14px;
        width: 156px;
        height: 82px!important;
    }
    .header-nav {
        position: sticky;
        display: flex;
        justify-content: space-between;
        height: 82px!important;
    }

    .header-nav-links {
        display: flex;
        width: 768px;
        justify-content: space-between;
        text-transform: uppercase;
        font-weight: 700;
        list-style: none;
        width: 65vw;
    }
    .header-nav-link {
        color: 12px;
        margin-right: 30px;
        margin-bottom: 0px;
        font-size: 14px;
        font-weight: 400;
        font-family: Marmelad;
        .active {
            color: #E8EBE4;
            font-size: 16px;
        }
        
    }
    .header-nav-link:hover {
        color: #E8EBE4;
        transform: scale(1);
    }
    .btn-nav-order {
        font-size: 16px;
        font-family: Marmelad;
        font-weight: 400;
        width: 156px;
    }

    .logo {
        margin: 0px 25px 0 10px;
        img {
            margin: 0px;
            width: 59px!important;
            height: 59px!important;
        }
    }

    .right-column {
        display: none;
    }
 }

 @media screen  and (max-device-width: 480px)  {
    .navbar-menu-content {
        background-color: #7C6958;
        width: 60vw;
        height: 100%;
        display: flex;

    }
    .navbar-burger-menu {
        position: fixed;
        left: -100%;
        right: 0;
        bottom: 0;   
        top: 0;
        height: 100%;
        width: 100vw;
        z-index: 10;
        transition: Left 0.4s;
    }

    .navbar-burger-menu.active {
        left: 0;
    }
    .header-nav-links {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        flex-direction: column;
        justify-content: center;
    }
    
    .header-nav-link {
        color: 12px;
        margin-right: 0px;
        margin-bottom: 30px;
        font-size: 24px;
        color: #fff;
        font-weight: 400;
        
        .active {
            border-bottom: solid 3px #E8EBE4;
            padding-bottom: 5px;
            color: #E8EBE4;
            font-size: 24px;
        }
    }
    .nav {
        position: sticky;
    }
    .mobile-btn {
        display: block;
        position: absolute;
        left: 1em;
        top: 0.7em;
        cursor: pointer;
        z-index: 99;
    }

    .btn-nav-order {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        font-size: 14px;
        font-weight: 400;
        font-family: Marmelad;
        width: 130px;
        height: 60px!important;
        background-image: url('../../../public/img/logo1.png');
        background-repeat: no-repeat;
        background-position: center;
        background-position-y: 13px;
        background-size: 30px;

    }
    .header-nav {
        position: relative;
        display: flex;
        justify-content: flex-end;
        height: 60px!important;
    }
    .right-column {
        display: none;
    }
    .logo {
        margin-bottom: 30px;
    }
}

@media screen and (max-device-width: 320px) {
    .navbar-burger-menu {
        position: fixed;
        left: -100%;
        right: 0;
        bottom: 0;   
        top: 0;
        height: 100vh;
        width: 60vw;
        background-color: #7C6958;
        z-index: 10;
        transition: Left 0.4s;
        .header-nav-links {
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            flex-direction: column;
            justify-content: center;
        }
    }
    .navbar-burger-menu.active {
        left: 0;
    }
    
    .header-nav-link {
        margin-bottom: 30px;
        font-size: 20px;
        color: #fff;
        font-weight: 400;
    }
    .nav {
        position: relative;
    }
    .mobile-btn {
        display: block;
        position: absolute;
        right: 265px;
        top: 7px;
        cursor: pointer;
        z-index: 11;
    }
    .header-nav-links {
        display: flex;
        width: 65vw;
    
    }
    .header-nav-link {
        color: 12px;
        margin-right: 15px;
    }
    
    .btn-nav-order {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        font-size: 14px;
        font-weight: 400;
        font-family: Marmelad;
        width: 130px;
        height: 51px!important;
        background-image: url('../../../public/img/logo1.png');
        background-size: 35px;
        background-repeat: no-repeat;
        background-position: center;
        background-position-y: 2px;

    }
    .header-nav {
        position: relative;
        display: flex;
        justify-content: flex-end;
        height: 51px!important;
    }
    .right-column {
        display: none;
    }
    .logo {
        
    }
}

